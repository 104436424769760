import {useEffect,useState} from 'react'
import ReactConfetti from 'react-confetti'

const Confetti = () => {
const [wd, setWd] = useState({width:window.innerWidth, height:window.innerHeight})
const sizeDetect = () => {
    setWd({width:window.innerWidth, height:window.innerHeight});
}

useEffect(() => {
window.addEventListener('resize',sizeDetect);
return () => {
    window.removeEventListener('resize',sizeDetect);   
}
},[wd])


  return (
    <ReactConfetti 
    width={wd.width}
    height={wd.height}
    style={{zIndex:"100000"}}
    />
  )
}

export default Confetti