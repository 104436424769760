import {Flexed,Flex,Img,Input,Button,Label,SelectBox } from '../../shared/Styles'
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import UserContext from '../../context/UserContext';


const EditProduct = ({id,setModal,twist, setTwist}) => {
    const {user} = useContext(UserContext);
const [product, setProduct] = useState({});
const [selectedid, setSelectedid] = useState('');
const [newproduct, setNewproduct] = useState({});

    useEffect(() => {
        const getProduct = async() => {
         axios
         //.get(`localhost:5000/api/products/${id}`)
        .get(`https://plsprintdemo.herokuapp.com/api/products/${id}`)
         .then(function (response) {
             setProduct(response.data.allproducts); 
             //onsole.log(response.target);
       })
      }
      getProduct();
    },[])



    const update = (e) => {
        const { name ,value } = e.target
        setProduct({...product,[name]:value})
        }


const updatenow = async() => {
    
//const newid =  await axios.get(`https://plsprintdemo.herokuapp.com/api/orders/nextid`)
await axios.patch(`https://plsprintdemo.herokuapp.com/api/products/patch`,product)

  .then(async(response) => {
    /*
    console.log(response)
    if(response.data.status === 1){
        await axios.post("localhost:5000/api/orders", {
       who:user.userid,
       orderid:newid,
       items:[{quantity:product.instock,name:"Admin Adjusted"}]
    })
       // 
    }
    */
    reset()
    
    })
    //
       
}
 const reset = () => {
    setTwist(!twist);

    setModal(false)
 }

 const deleteProduct = async(id) => {
   
    await axios.post(`https://plsprintdemo.herokuapp.com/api/products/delete/${id}`)
    //await axios.post(`http://localhost:5000/api/products/delete/${id}`)

    .then(response => {
      reset()
    })
 }





console.log(product)

  return (
    <div style={{padding:"20px",width:"100%"}}><span style={{fontSize:"1.3rem",fontWeight:"bold",color:"var(--primamryBlue)"}}>Item # {product?.stockid}</span><br /><br />
<Flexed padding="0px 0px 15px 0px">
<Flex><Label>Product Name</Label><br /><Input type="text" defaultValue={product?.name} onChange={update} name="name"/></Flex>
<Flex><Label>Item Number</Label><br /><Input type="text" defaultValue={product?.stockid} onChange={update} name="stockid" /></Flex>
<Flex><Label>Category</Label><br />
<SelectBox defaultValue={product?.cat}  onChange={update} name="cat" width="92%">
    <option value="Envelopes">Envelopes</option>
    <option value="Letter Heads">Letter Heads</option>
    <option value="Business Cards">Business Cards</option>
    </SelectBox>
    </Flex>
</Flexed>
<Flexed  padding="0px 0px 15px 0px">
<Flex><Label>Reorder At</Label><br /><Input type="text" defaultValue={product?.reorderlvl} onChange={update} name="reorderlvl"/></Flex>
<Flex><Label>In Stock</Label><br /><Input type="text" defaultValue={product?.instock} bgColor="rgb(0,0,0,.1)" disabled={true}/></Flex>
<Flex><Label>Paper Type</Label><br /><Input type="text" defaultValue={product?.type} onChange={update} name="type"/></Flex>
<Flex><Label>Size</Label><br /><Input type="text" defaultValue={product?.size} onChange={update} name="size" /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
<Flex><Label>Colors</Label><br /><Input type="text" defaultValue={product?.colors} onChange={update} name="colors"/></Flex>
<Flex><Label>Per Package</Label><br /><Input type="text" defaultValue={product?.perpack} onChange={update} name="perpack"/></Flex>
<Flex><Label>Must Order In</Label><br /><Input type="text" defaultValue={product?.mustorder} onChange={update} name="mustorder" /></Flex>
<Flex><Label>Price</Label><br /><Input type="text" defaultValue={product?.price} onChange={update} name="price"/></Flex>
<Flex><Label>Taxable</Label><br /><Input type="text" defaultValue={product?.tax} onChange={update} name="tax"/></Flex>
</Flexed>
<Flexed justify="space-between" alignItems="flex-start">
    <Flex ><br /><br /><br /><br /><Button background="#ff6961" hbackground="red" type="button" onClick={() => deleteProduct(product?.id)}>Delete Product</Button></Flex>
    <Flex textAlign="right" padding="0px 20px 0px 0px"><Button type="button" onClick={updatenow}>Update Product</Button><br /><br /><Button onClick={() => setModal(false)}>Cancel</Button></Flex>
</Flexed>

    </div>
  )
}

export default EditProduct