import {useContext} from 'react'
import { Wrap,NavLink } from './NavStyles'
import { Flexed, Flex, Img } from '../../shared/Styles'
import Link from '../links/Link'
import UserContext from '../../context/UserContext'
import {Capitalize } from '../../shared/Formatters'

const Nav = () => {
const { user,Logout } = useContext(UserContext);

const firstName = user.fullname?.split(" ").shift();
  return (
    <Wrap>
      <Flexed margin="0px auto" width="calc(100% - 10%)" justify="flex-between" style={{height:"50px",}}>

<Flex flex="1.5">
<Link text="Home" path="/home" />
{user.type === 1 && <Link text="Products" path="/products" />}
<Link text="Inventory" path="/inventory" />
<Link text="Order History" path="/orders" />
<Link text="Users" path="/users" />
</Flex>
<Flex textAlign="right">Welcome {firstName}&nbsp;&nbsp;| &nbsp;&nbsp;<span onClick={Logout}>Log Out</span></Flex>
      </Flexed>
      
      </Wrap>
  )
}

export default Nav