import {useState,useEffect} from 'react'
import BG from '../../assets/Asset3.png'
import {Flexed,Flex,Img,Input,Button } from '../../shared/Styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const Inventory = () => {


    const [inventory, setInventory] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
 
        const getProduct = async() => {
         axios
         .get(`https://plsprintdemo.herokuapp.com/api/products`)
         .then(function (response) {
            setInventory(response.data.allproducts);
           
         });
     }
     getProduct();
    },[])

  return (
    <>
    <div style={{}}><Img src={BG} alt="" width="100%" maxHeight="187px" margin="0px 0px 0px -2px"/>
    
    
    <div style={{width:"1200px",margin:"50px auto"}}>
<div style={{fontSize:"1.5rem",padding:"15px 0px"}}>Product Inventory {inventory.length > 0 && ` - ${inventory.length} Items`}</div>
{
    inventory.length === 0 ? <p>No inventory of this order is available</p> :

    <Flexed  style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">
<Flex></Flex>
<Flex flex=".5">Item#</Flex>
<Flex flex="1.5">Name</Flex>
<Flex> Category</Flex>
<Flex>Reorder At</Flex>
<Flex>In Stock</Flex>


    </Flexed>
}
 
{inventory.map(item => {
  return    <Flexed  key={item._id} style={{padding:"10px",border:"1px solid lightgrey"}} className="child" onClick={() => navigate(`/inventory/item/${item._id}`)}>
     <Flex textAlign="center"><Img src={item.img} maxHeight="50px"  border="1px solid rgb(0,0,0,.3)" className="hover zoom"/></Flex>
     <Flex flex=".5" >{item.stockid}</Flex>
     <Flex flex="1.5">{item.name}</Flex>
     <Flex >{item.cat}</Flex>
     <Flex >{item.reorderlvl}</Flex>
     <Flex color={item.instock < item.reorderlvl ? "red" : ""} fontWeight={item.instock < item.reorderlvl ? "bold" : ""}>{item.instock}</Flex>
         </Flexed>
})}



    </div>
   
    
    </div>
     
    
     
     
   
     </>
  )
}

export default Inventory