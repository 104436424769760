import {useState,useEffect,useContext} from 'react'
import {Flexed,Flex,Img,Input,Button } from '../../shared/Styles'
import { useParams  } from "react-router-dom";
import axios from 'axios'
import { BsDot } from 'react-icons/bs'
import CartContext from '../../context/CartContext';
import UserContext from '../../context/UserContext';
import TempContext from '../../context/TempContext';
import { useCookies } from "react-cookie";

import { sendReorder } from '../../email/Email'
import {sendNotifications} from '../../email/sendMail'
const { v4: uuidv4 } = require('uuid');




const InventoryItem = () => {
    const {id} = useParams();
    const { items, setItems, updates, setUpdates } = useContext(CartContext);
   const [product, setProduct] = useState({});
   const [history, setHistory] = useState([]);
   const [orderquantity, setOrderquantity] = useState(0);
const [showComplete, setShowcomplete] = useState(false);
const [showComplete2, setShowcomplete2] = useState(false);
const [newerror, setNewerror] = useState(false);
const { user } = useContext(UserContext);
const { temp,setTemp,setInventory,inventory } = useContext(TempContext);
const [reorderquantity, setReorderquantity] = useState(0);
const [reordermessage, setReordermessage] = useState('');
const [newquan, setNewquan] = useState(0);
const [cookies, setCookie, removeCookie] = useCookies();
const [r, setR] = useState(0);


const getquan = () => {
  let n = 0;

  const ne = items.filter(item => item.stockid === product.stockid)
  if(ne.length === 0){
    setNewquan(product.instock)
      }else{
     const ne = items.filter(item => item.stockid === product.stockid);
     ne.map(item => {n = n + parseInt(item.quantity)})
      }
    setNewquan(product.instock - n)
    setR(n)
  
}





useEffect(() => {
  const getProduct = async() => {
   axios
   .get(`https://plsprintdemo.herokuapp.com/api/products/${id}`)
   .then(function (response) {
       setProduct(response.data.allproducts); 
       
 })
}
const getProductHistory = async() => {
   const dbid = id;
   try{
     axios
     .get(`https://plsprintdemo.herokuapp.com/api/orders/order/${dbid}`)
     .then(function (response) {
        setHistory(response.data.allorders);
        
     })
     .catch(err => console.log(err))
   }catch(err){
    // console.log(err)
   }
  
}


getProduct();
getProductHistory();

},[]);




    


 
useEffect(() => {
 getquan();
},[items,product])

const addcart  = (index) => {
const uni = uuidv4();
    if(orderquantity > 0){
        setItems((items) => [
            ...items,
            {
              id:product._id,
              name:product.name,
              stockid:product.stockid,
              price:product.price,
              taxable:product.taxable,
              size:product.size,
              type:product.type,
              colors:product.colors,
              cat:product.cat,
              quantity:orderquantity,
              img:product.img,
         
            },
          ])
//newtemp = items.filter(item => item.stockid === product.stockid);//.map(ite => {return aquan = parseInt(newquan) + parseInt(ite.quantity)})
//setNewquan(...newquan => [...newquan,newtemp])
//console.log(newtemp)

          setOrderquantity(0)
          setShowcomplete(true)
          setTimeout(() => {
            setShowcomplete(false)
          },3000);



    }

}





   






const preaddcart = async() => {

  let check = orderquantity % product.mustorder;
if(check === 0){
addcart();
}else{
  setNewerror(true)
}
}

const reordersend = () => {
  const firstName = user.fullname?.split(" ").shift();
  if(reorderquantity > 0){
    const reorderinfo = {
      quantity:reorderquantity,
      itemnum:product.stockid,
      name:product.name,
      who:firstName,
      company:"PSCU",
      sender:user.email,
   

    }
    
    const plenish = {
      who:user.userid,
        stockid:product._id, 
        quantity:reorderquantity,
        replenished:0
    }
    
    let reorderReply;
    try{
      
      axios.post(`https://plsprintdemo.herokuapp.com/api/orders/replenish`,plenish)
      .then(function (response) {
        sendNotifications(reorderinfo)
    //sendReorder(reorderinfo);
      
     
        setReordermessage("Reorder Submitted")
       
   
      });
    }catch(err){
    console.log(err)
    }
    
  //  setShowcomplete2(true)
   // setTimeout(() => {
   //   setReorderquantity(0);
    //  setShowcomplete2(false)
   // },3000);
    
    
      }
}





  return (

    <div style={{width:"1250px",margin:"0px auto"}}> 
 <Flexed wrap="wrap"  margin="20px auto 0px auto"  alignItems="flex-start" justify="flex-start">
<Flex textAlign="right" flex="1.3">
    <br /> <br />
<Img src={`/${product.img}`} alt=""   boxShadow="0 0 8px black" maxHeight="255px"/>
</Flex>
<Flex padding="0px 15px">
<Flexed flexDirection="column">
<Flex textAlign="center" fontSize="2rem" color="var(--primamryBlue)">
    {product.name}
    </Flex>
    <Flex fontSize="1.3rem">
    &nbsp;&nbsp;&nbsp;&nbsp;Item # {product.stockid} - {product.name}<br />
    <BsDot />{product.size}<br />
    <BsDot />{product.type}<br />
    <BsDot />{product.colors}<br />  
    <BsDot />{product.perpack} Per Package<br />
    <span style={{color:"red",fontWeight:"bold",fontSize:"1.2rem"}}><BsDot />Must Be Ordered In Increments of {product.mustorder}</span><br />
    <BsDot />Quantity In Stock: {newquan } {r > 0 && <><span style={{fontSize:"1rem"}}>    ({r} in cart)</span><br /></>}
</Flex>
<Flexed>
    <Flex flex=".3" padding="20px 0px"><Input margin="0 0 0 10px" height="45px" width="120px" type="number"  step={product.mustorder} min={product.mustorder} max={product.instock} onFocus={() => {setNewerror(false)}} onChange={(e) => setOrderquantity(e.target.value)}  value={orderquantity}  required/></Flex>
    <Flex padding="0 0 0 20px">{orderquantity <= newquan ? <Button onClick={preaddcart}>Add To Cart</Button> :  'Quantity exceeds stock'} {newerror && <p style={{color:"red", fontSize:"1.2rem",fontWeight:"bold"}}>Quantity not in {product.mustorder} Increment</p>}</Flex>
    
</Flexed>

</Flexed>
</Flex>

        </Flexed>

        <Flexed width="55%" margin="0px auto" textAlign="right"><Flex textAlign="right" style={{fontWeight:product.instock < product.reorderlvl && "bold",fontSize:"1.2rem",color:product.instock < product.reorderlvl && "red"}}>Reorder {product.instock < product.reorderlvl && 'Now'}   <Input margin="0 0 0 10px" height="45px" width="120px" type="number"  value={reorderquantity} onChange={(e) => setReorderquantity(e.target.value)}/> &nbsp;&nbsp;<Button onClick={reordersend} disabled={!setShowcomplete2}>Send Request </Button></Flex></Flexed>
        <div className={`alert-success ${showComplete ? 'alert-shown' : 'alert-hidden'}`}>
          <strong>Added To Cart! Thank You</strong> 
        </div>
        <div className={`alert-success ${showComplete2 ? 'alert-shown' : 'alert-hidden'}`} style={{color:reordermessage === "Reorder Failed." ? "red" : "green"}}>
          <strong>{reordermessage}</strong> 
        </div>

<div style={{width:"1048px",margin:"100px auto"}}>
<div style={{fontSize:"1.5rem",padding:"15px 0px"}}>Item History {history?.length > 0 && ` - ${history?.length} Transactions`}</div>
{
    history?.length === 0 ? <p>No History of this order is available</p> :
    history?.map((item) => {
  
      return(
     <>
   
  <Flexed margin="40px 0px 0px 0px " key={item?._id} style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">
  <Flex margin="0">Order # {item?.orderid}</Flex>
  
  <Flex>Ordered By: {item.who?.fullname}</Flex>
  <Flex>Date Ordered: {new Date(item?.createdAt).toDateString()}</Flex>
  <Flex>Status: {item?.status}</Flex>
   </Flexed>
  {history.length > 0 &&  <Flexed  style={{background:"var(--primamryBlue)",opacity:".7",color:"white"}} margin="-1px 0px 0px 0px" padding="2px 5px"  border="1px solid lightgrey">

  <Flex>Item #</Flex>
 <Flex flex="2.1">Item Name</Flex>
 <Flex>Quantity</Flex>
 
 
 </Flexed>}
   {item.items.map((prod) => {
     return (
     <Flexed key={prod?.id} margin="-2px 0px 0px 0px" padding="10px 5px" className={`${prod.id === id ? 'highlight' : 'child'}`} border="1px solid lightgrey" >
      
 
  <Flex>{prod?.stockid}</Flex>
 <Flex flex="2.1">{prod?.name}</Flex>
 <Flex>{prod?.quantity}</Flex>
 
 </Flexed>
     )
     
   })}
  
         
         </>
      )
   
    }) 
  }
    </div>
    </div>
 
  )
}

export default InventoryItem