import { useState } from 'react'
import axios from 'axios'

import {Flexed,Flex,Img,Input,Button,SelectBox,Checkbox } from '../../shared/Styles';
import ErrorModal from '../../components/modal/ErrorModal'





const SystemUser = ({setAdduser,twist,setTwist}) => {
    const [error, setError] = useState();
    const [newselectedUser, setNewselectedUser] = useState({
        username:'' ,
        password:'',
        email:'',
        status:1,
        type:1,
        fullname:'' ,
        title:'' ,
        auth:1,

    });
const [passworderror, setPassworderror] = useState(false);
const [confirm, setConfirm] = useState('');
const [pass, setPass] = useState('');

const errorReset = () => {
    setError(null);
}

const checkconfirm = (e) => {
    if(confirm > ""){
        if(e.target.value !== confirm){
        setPassworderror(true);
        }else{
            setPassworderror(false)
        }
      
          }
          setPass(e.target.value)
}

const confirmpass = (e) => {
    if(e.target.value > ""){
  if(e.target.value !== pass){
  setPassworderror(true);
  }else{
      setPassworderror(false)
  }
  
    }
    setConfirm(e.target.value)
  }


const addNew = async(e) => {
    e.preventDefault();
    const newuser = {
        username:newselectedUser.username ,
        password: pass,
        email:newselectedUser.email,
        status:newselectedUser.status,
        type:newselectedUser.type,
        fullname:newselectedUser.fullname ,
        title:newselectedUser.title ,
        auth: newselectedUser.auth,
    }

   await axios.post('https://plsprintdemo.herokuapp.com/api/users',newuser)
    .then((response) => {
        setTwist(!twist);
       closeAdd()
        
    })
    .catch(err => {
        setError(err.response.data.message)
            })
            
}

const closeAdd = () =>{
    setConfirm('')
    setPass('')
    setPassworderror(false)  
    setAdduser(false)
}





  return (
    <>
    <ErrorModal error={error} onClear={errorReset} />

    <form onSubmit={addNew} style={{zIndex:"-10"}}>
  <Flexed padding="10px 0px">
    <Flex>Full Name<br /><Input value={newselectedUser.fullname} onChange={e => setNewselectedUser({...newselectedUser,fullname:e.target.value})} required/></Flex>
    <Flex>User Name<br /><Input defaultValue={newselectedUser.username} onChange={e => setNewselectedUser({...newselectedUser,username:e.target.value})} required /></Flex>
    </Flexed>
    <Flexed padding="10px 0px">
    <Flex>Title<br /><Input defaultValue={newselectedUser.title} onChange={e => setNewselectedUser({...newselectedUser,title:e.target.value})} /></Flex>
    <Flex>Email<br /><Input textTransform="none" type="email" defaultValue={newselectedUser.email} onChange={e => setNewselectedUser({...newselectedUser,email:e.target.value})}  required/></Flex>
    </Flexed>
    <Flexed padding="10px 0px">
    <Flex>Password<br /><Input type="password" textTransform="none"  onChange={checkconfirm} required/></Flex>
    <Flex>Confirm Password<br /><Input  type="password" textTransform="none" onChange={confirmpass} required/><br /></Flex>
    <div >{passworderror ? <span style={{position:"absolute",top:"-5px",right:"19%",color:"red",fontWeight:"bold"}}>Passwords do not match</span> : "" }</div>
    </Flexed> 
     <Flexed padding="10px 0px">
     <Flex >Status<br /> 
     <SelectBox width="90%" value="1">
            <option value="1" >Active</option>
        </SelectBox>
        </Flex>
    <Flex >Type<br /> 
    <SelectBox width="90%" value="1">
        <option value="1" >System User</option>
      </SelectBox></Flex>
     <Flex>Auth<br />
      <SelectBox width="90%" value={newselectedUser.auth}  onChange={e => setNewselectedUser({...newselectedUser,auth:e.target.value})}>
        <option value="0">User</option>
        <option value="6">Admin</option>
        <option value="1">Account Manager</option>
      </SelectBox>
      </Flex>

    
  
   
    </Flexed>

    <Flexed justify="space-between" padding="0px 10%"><Button type="submit" disabled={passworderror}>Add User</Button><Button type="button" onClick={closeAdd}>Cancel</Button></Flexed>
    </form>
    </>
  )
}

export default SystemUser