
import { Flexed, Flex, Img } from '../../shared/Styles'
import { useContext, } from 'react'
import Support from '../../assets/support.png'
import CartIcon from '../../assets/cart.png'
import Logo from '../../assets/download.png'
import Nav from '../../components/nav/Nav'
import { Routes, Route,useNavigate } from 'react-router-dom'
import Home from '../home/Home'

import InventoryItem from '../inventory/InventoryItem'
import OrderHistory from '../orders/OrderHistory'
import Inventory from '../inventory/Inventory'
import Cart from '../cart/Cart'
import UserContext from '../../context/UserContext'
import AppLogout from '../../AutoLog'
import {TempProvider } from '../../context/TempContext'
import Product from '../product/Product'

import CartContext from '../../context/CartContext'
import Login from '../login/Login'
import Users from '../users/Users'
import Ship from '../ship/Ship'

const Dashboard = () => {

  const { items } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();


  return (
 <>
{!user.logged ? 
  <Routes>
  <Route path="/login" element={<Login />} />
  <Route path="*" element={<Login />} />
    </Routes>
:
<AppLogout>
<TempProvider>
<div>
    <Flexed justify="space-between" alignItems="flex-end" padding="0px 0px 5px 0px" margin="20px auto 0px auto" width="calc(100% - 10%)">
        <Flex flex="2"><Img src={Logo} height="80px"/></Flex>
        <Flex style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}><Img src={Support} height="42px" width="" margin="0 20px 0 0" /><Img className="child" src={CartIcon} height="33px" onClick={() => navigate(`/cart`)} /><span style={{position:"absolute", bottom:"32px",right:"0",zIndex:"250",fontSize:"1.3rem",fontWeight:"bold",color:"green"}}>{items.length}</span></Flex>
   </Flexed>
   <Nav />
   <div style={{minHeight:"80vh"}}>
   <Routes>
<Route path="/login" element={<Home />} />

    <Route path="/ship/:id" element={<Ship />} />
    <Route path="/home" element={<Home />} />
    <Route path="/inventory/item/:id" element={<InventoryItem />} />
    <Route path="/orders" element={<OrderHistory />} />
    <Route path="/cart" element={<Cart />} />
    <Route path="/inventory" element={<Inventory />} />
    <Route path="/products" element={<Product />} />
    <Route path="/users" element={<Users />} />
    <Route path="/products" element={<Product />} />
  

    <Route path="*" element={<Home />} />
    </Routes>

    </div>
   </div>
   </TempProvider>
   </AppLogout>
}
   </>
  )
}

export default Dashboard