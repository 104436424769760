import React, { createContext, useState, useEffect } from "react";
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'


const UserContext = createContext();

export function UserProvider({ children }) {
    const [cookies, setCookie, removeCookie,removeCookies] = useCookies();
    const [twister, setTwister] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [loading, setLoading] = useState(false);


  const [user, setUser] = useState({
    name:'',
    logged: false,
    fullname:'',
    username:'',
    userid:'',
    auth:'',
    email:''
  });

const NAV = useNavigate();


useEffect(() => {
    if(cookies.userid){
       // getUserinfo(cookies.userid)

       setUser({
        logged: cookies.logged,
        username:cookies.username,
        fullname:cookies.fullname,
        userid:cookies.userid,
        type:parseInt(cookies.type),
        status:parseInt(cookies.status),
        auth:parseInt(cookies.auth),
        email:cookies.email

       })
      }else{
        setUser({});
        NAV('/')
      }
     
},[])


const checklogin = async({logininfo,setError}) => {
setLoading(true)
let re;
 await axios.post('https://plsprintdemo.herokuapp.com/api/users/login',logininfo)

.then(response => {

     re = {
        logged: true,
        username:response.data.userinfo.username,
        fullname:response.data.userinfo.fullname,
        userid:response.data.userinfo._id,
        type:response.data.userinfo.type,
        status:response.data.userinfo.staus,
        auth:response.data.userinfo.auth,
        email:response.data.userinfo.email
    }
    setCookie("logged", true);
    setCookie("userid", response.data.userinfo._id);
    setCookie("username", response.data.userinfo.username);
    setCookie("type", parseInt(response.data.userinfo.type));
    setCookie("status", parseInt(response.data.userinfo.status));
    setCookie("auth", parseInt(response.data.userinfo.auth));
    setCookie("email", response.data.userinfo.email);
    setCookie("fullname", response.data.userinfo.fullname);
    setUser(re)
   
})
.catch (err => {
    setError(err.response.data.message)
})
setLoading(false)
}

const Logout = () => {
removeCookie("logged");
  removeCookie("userid");
  removeCookie("username");
  removeCookie("type");
  removeCookie("status");
  removeCookie("auth");
  removeCookie("email");
  removeCookie("fullname");

  setUser({
    name:'',
    logged: false,
    username:'',
    userid:'',
    auth:'',
    email:'',
    fullname:''
  });
  NAV('/')
}

const checkCookie = () => {
    if(cookies.userid){
       // getUserinfo(cookies.userid)

       setUser({
        logged: cookies.logged,
        username:cookies.username,
        userid:cookies.userid,
        type:parseInt(cookies.type),
        status:parseInt(cookies.status),
        auth:parseInt(cookies.auth),
        email:cookies.email,
        fullname:cookies.fullname
       })
      }else{
        setUser({})
      }
}

  /*
  const [items, setItems] = useState([]);

  const addCart = (
    name,
    instock,
    stockid,
    price,
    taxable,
    size,
    type,
    colors,
    perpack,
    mustorder,
    cat,
    who,
    quantity,
    when,
    id,
    dbid,img
  ) => {
    setItems((prevState) => [
      ...prevState,
      {
        name,
        instock,
        stockid,
        price,
        taxable,
        size,
        type,
        colors,
        perpack,
        mustorder,
        cat,
        who,
        quantity,
        when,
        id,
        dbid,
        img
      },
    ]);
  };

  const delCart = (id) => {
    const newPeople = items.filter((item) => {
      return item.id !== id;
    });
    setItems(newPeople);
  };

  const updateQuantity = (id, newquantity) => {
    const exist = items.find((x) => x.id === id);
    setItems(
      items.map((x) => (x.id === id ? { ...exist, quantity: newquantity } : x))
    );
  };

  const addOrder = () => {
    items.map((item) => {
      axios
        .post("http://localhost:5000/api/orders", {
          name: item.name,
          instock: item.instock,
          stockid: item.stockid,
          price: item.price,
          taxable: item.taxable,
          cat: item.category,
          size: item.size,
          type: item.type,
          colors: item.colors,
          perpack: item.perpack,
          mustorder: item.mustorder,
          who: item.who,
          quantity: item.quantity,
          when: item.when,
          id: item.id,
          orderid: uuidv4(),
          dbid: item.dbid,
          quantityleft: item.left,
        })
        .then((response) => {
          // window.location.href = `/customer`;
          // setContacts([...contacts, response.data.contact]);
          //setModal(false);
          // setResett(true);
        })
        .catch((error) => {
          console.log(error);
          //setError(error.response.data.message || "Som thik Wong");
        });
    });
  };
*/
  return (
    <UserContext.Provider
      value={{
 
        user,
        setUser,
        checklogin,
        checkCookie,
        Logout,
        loading
        
        
       
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
