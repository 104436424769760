import styled from 'styled-components'



export const Flexed = styled.div`
display:flex;
position:relative;
width:${props => props.width || "100%"};
height:${props => props.height || ""};
justify-content:${props => props.justify || "center"};
align-items:${props => props.alignItems || "center"};
padding:${props => props.padding || ""};
flex-direction:${props => props.flexDirection || "row"};
backgroud-color:${props => props.background || ""};
flex-wrap:${props => props.wrap || ""};
color:${props => props.color || ""};
margin:${props => props.margin || "0px"};
border:${props => props.border || ""};
-webkit-flex-wrap: wrap;
  flex-wrap: wrap;

`;

export const Flex = styled.div`

flex:${props => props.flex || "1"};
width:${props => props.width || "100%"};
margin:${props => props.margin || "0px"};
position::${props => props.position || "relative"};
text-align:${props => props.textAlign || "left"};
font-size:${props => props.fontSize || "1rem"};
padding:${props => props.padding || "0px"};
color:${props => props.color || 'inherit'};
font-weight:${props => props.fontWeight || 'inherit'};
text-transform:${props => props.textTransform || "capitalize"};
border:${props => props.border || ""};
`;

export const Img = styled.img`
width:${props => props.width || ""};
height:${props => props.height || "100%"};
object-fit: contain;
margin:${props => props.margin || ""};
padding:${props => props.padding || "0px"};
box-shadow:${props => props.boxShadow || ""};
max-height:${props => props.maxHeight || ""};
transition:transform .5s ease;
z-index:100;
position:relative;
border:${props => props.border || ""};

&.hover:hover{
  cursor:pointer;

  &.zoom{
    transform: scale(2);
    box-shadow:0 0 8px black;
    z-index:2250;
  }

}


`;

export const Button = styled.button`
width:${props => props.width || ""};
height:${props => props.height || "100%"};
margin:${props => props.margin || ""};
padding:${props => props.padding || "7px 20px"};
border-radius:${props => props.borderRadius || "4px"};
background:${props => props.background || "var(--primaryGrey)"};
color:${props => props.color || "white"};
display:${props => props.display || "inline-block"};
font-size:${props => props.fontSize || ".8rem"};
transition:${props => props.transition || "all 0.3s ease"};
cursor: pointer;
outline: none;
border:1px solid grey;
margin:20px 0px;
    
  :focus {
    outline: none;
  }
  
:hover{
  background:${props => props.hbackground || "var(--primamryBlue)"};
   
    border-color: var(white);
  }

`;

export const Input = styled.input`
width:${props => props.width || "90%"};
padding:${props => props.padding || "6px 0px 6px 6px"};
border:${props => props.styl || "1px solid lightgrey"};
border-radius:${props => props.bradius || "6px"};
font-size:${props => props.fontSize || "var(--fontSize)"};
text-align:${props => props.textAlign || "left"};

outline:none;
position:relative;
transition:all .3s ease;


text-transform:${props => props.textTransform || "capitalize"};
outline:none;
position:relative;
background-color:${props => props.bgColor || ""};
:focus{

    border:${props => props.hoverBorder || "1px solid rgb(122,187,67,.6)"};
    box-shadow: 0px 0px 3px;
    background-color:white;
    text-align:${props => props.FocusAlign || "left"};;
    
}

`;

export const SelectBox = styled.select`

width:${props => props.width || "100%"};
padding:${props => props.padding || "3px 0px 0px 5px"};
border:${props => props.border || "1px solid lightgrey"};
border-radius:${props => props.bradius || "6px"};
font-size:${props => props.fontSize || "var(--fontSize)"};
text-align:${props => props.textAlign || "left"};

outline:none;
position:relative;
transition:all .3s ease;
 
}
`;

export const Checkbox = styled.input`
width:${props => props.width || "90%"};
padding:${props => props.padding || "0px 0px"};

font-size:${props => props.fontSize || "var(--fontSize)"};
text-align:${props => props.textAlign || "left"};




`;


export const Label = styled.label`

padding:${props => props.padding || "0px 6px"};

`;