import styled from "styled-components";
import { NavLink } from 'react-router-dom'

export const Links = styled(NavLink)`
flex:1;
color:${props => props.color || "inherit"};
font-size:${props => props.fontSize || "1.1rem"};
padding:${props => props.padding || "0px 0px"};
margin:${props => props.padding || "0px 50px 0px 0px"};
transition: all .5s ease-in;

&.active {
font-size:1.4rem;
font-style:italic;
text-decoration:underline
  }
`;