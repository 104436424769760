import {useContext,useState,useEffect} from 'react'
import {Flexed,Flex,Img,Input,Button } from '../../shared/Styles'
import BG from '../../assets/download.png'
import UserContext from '../../context/UserContext'
import axios from 'axios'
import ErrorModal from '../../components/modal/ErrorModal'
import Spin from '../../components/spinner/Spin'

import {useNavigate } from 'react-router-dom'




const Login = () => {
const Navigate = useNavigate();
const [error, setError] = useState();

const { user, setUser,checklogin,spinner,loading } = useContext(UserContext);


const [logininfo, setLogininfo] = useState({
    username:'',
    password:''
});

useEffect(() => {
    
},[])


const getUser = async(e) => {
    e.preventDefault();
    
    checklogin({logininfo,setError})

}




const errorReset = () => {
    setError(null);
}
  return (
    <>
    {loading && <Spin asOverlay />}
    <ErrorModal error={error} onClear={errorReset} />
    <div style={{backgroundColor:"#F6F6F6",height:"100vh"}}>
    <form >
  <div style={{width:"260px",margin:"0px auto",paddingTop:"15vh",textAlign:"center",background:"#F6F6F6"}}>
    <Flexed flexDirection="column"  justify="flex-start" >  
<Flex textAlign="center"><Img src={BG} alt="" maxHeight="80px" /></Flex>
<Flex padding="15px 0px">UserName:<br /><Input textTransform="none" textAlign="left" onChange={e => setLogininfo({...logininfo,username:e.target.value})}/></Flex>
<Flex>Password:<br /><Input  type="password" textTransform="none"  textAlign="left" onChange={e => setLogininfo({...logininfo,password:e.target.value})}/></Flex>


</Flexed>
<Button onClick={getUser} >Login</Button>
</div>
</form>
</div>
</>
  )
}

export default Login