import {useState,useEffect} from 'react'
import BG from '../../assets/products.png'
import {Flexed,Flex,Img,Input,Button } from '../../shared/Styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import EditProduct from './EditProduct'
import Modal from '../../components/modal/Modal'
import Addnew from './Addnew'



const Product = () => {
    const [selectedid, setSelectedid] = useState(null);

    const [twist, setTwist] = useState(false);
    const [addnew, setAddnew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [inventory, setInventory] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
 
        const getProduct = async() => {
         axios
         .get(`https://plsprintdemo.herokuapp.com/api/products`)
         .then(function (response) {
            setInventory(response.data.allproducts);
  
         });
     }
     getProduct();
    },[twist])



  return (
    <>
     
    <div style={{}}><Img src={BG} alt="" width="100%" maxHeight="187px" margin="0px 0px 0px -2px"/>
    <Modal
          show={edit}
          style={{width:"85%",left:"7.5%"}}
          header="Edit Current Product"
        >
          <EditProduct
            id={selectedid}
            //cats={Cats}
            setModal={setEdit}
            setTwist={setTwist}//setResett={setResett}
           twist={twist}
            //id={edit}
          />
        </Modal>
        <Modal
          show={addnew}
          style={{width:"85%",left:"7.5%"}}
          header="Add New Product"
        >
          <Addnew 
            setModal={setAddnew}
            setTwist={setTwist}//setResett={setResett}
           twist={twist}
          />
        </Modal>
    
    <div style={{width:"1200px",margin:"50px auto"}}>
<Flexed>
<Flex style={{fontSize:"1.5rem",padding:"15px 0px"}}>Product Inventory {inventory.length > 0 && ` - ${inventory.length} Items`}</Flex>
<Flex textAlign="right"><Button onClick={() => setAddnew(true)}>Add Product</Button></Flex>
</Flexed>
{
    inventory.length === 0 ? <p>No inventory of this order is available</p> :

    <Flexed  style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">
<Flex></Flex>
<Flex flex=".5">Item#</Flex>
<Flex flex="1.5">Name</Flex>
<Flex> Category</Flex>
<Flex>Reorder At</Flex>
<Flex>In Stock</Flex>
<Flex flex=".5"></Flex>



    </Flexed>
}
 
{inventory.map(item => {
  return    <Flexed  key={item._id} style={{padding:"10px",border:"1px solid lightgrey"}} className="child" >
     <Flex textAlign="center"><Img src={item.img} maxHeight="50px"  border="1px solid rgb(0,0,0,.3)" className="hover zoom"/></Flex>
     <Flex flex=".5" >{item.stockid}</Flex>
     <Flex flex="1.5">{item.name}</Flex>
     <Flex >{item.cat}</Flex>
     <Flex >{item.reorderlvl}</Flex>
     <Flex color={item.instock <= item.reorderlvl ? "rgb(255,0,0,.5)" : ""} fontWeight={item.instock < item.reorderlvl ? "bold" : ""}>{item.instock}</Flex>
     <Flex flex=".5"><FiEdit onClick={() => {setSelectedid(item._id);setEdit(true)}}/></Flex>
         </Flexed>
})}



    </div>
   
    
    </div>
     
    
     
     
   
     </>
  )
}

export default Product