import { useState,useEffect,useContext } from 'react'
import axios from 'axios'

import {Flexed,Flex,Img,Input,Button,SelectBox,Checkbox } from '../../shared/Styles';
import ErrorModal from '../../components/modal/ErrorModal'
import UserContext from'../../context/UserContext'

const EditUser = ({setShowmodal,twist,setTwist,seluser})  => {
  const {user,setUser,getUserinfo} = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState({
    auth:seluser.auth,
    fullname: seluser.fullname,
    email: seluser.email,
    id:seluser.id,
    password:seluser.password,
    status:seluser.status,
    title:seluser.title,
    type:seluser.type,
    username:seluser.username,
    newpassword:null


  })
  const [passworderror, setPassworderror] = useState(false);
const [confirm, setConfirm] = useState('');
const [pass, setPass] = useState();
const [error, setError] = useState();
const [changepass, setChangepass] = useState(true);

/*useEffect(() => {
const useerr = user.user;
setSelectedUser(useerr)
},[])
*/
const checkconfirm = (e) => {
  e.preventDefault();
  if(confirm > ""){
      if(e.target.value !== confirm){
      setPassworderror(true);
      }else{
          setPassworderror(false)
      }
    
        }
        setPass(e.target.value)
}

const confirmpass = (e) => {
  e.preventDefault();
  if(e.target.value > ""){
if(e.target.value !== pass){
setPassworderror(true);
}else{
    setPassworderror(false)
}

  }
  setConfirm(e.target.value)
}
const errorReset = () => {
  setError(null);
}


const closeModal = () =>{
  setChangepass(true);

  setConfirm('')
  setPass('')
  setPassworderror(false)  
  setShowmodal(false)
}

const DeleteUser = async() => {

 
  try{
    await axios.delete(`https://plsprintdemo.herokuapp.com/api/users/delete/${selectedUser.id}`)
    .then((response) => {
   
      setTwist(!twist);
      setShowmodal(false)
  
  })
  .catch(err => {
      setError(err.response.data.message)
          })
  }catch(err){
    setError(err)
  }

         
}

const updateUser = async(e) => {
e.preventDefault();

const newuser = {
    username:selectedUser.username ,
    password:selectedUser.password,
    email:selectedUser.email,
    status:parseInt(selectedUser.status),
    type:parseInt(selectedUser.type),
    fullname:selectedUser.fullname ,
    title:selectedUser.title ,
    auth:parseInt(selectedUser.auth) ,
    id:selectedUser.id,
    newpassword:pass
}

await axios.patch(`https://plsprintdemo.herokuapp.com/api/users/patch/${selectedUser.id}`,newuser)
.then(() => {


  setTwist(!twist);
 setShowmodal(false)
getUserinfo(user._id)
  
})
.catch(err => {
    setError(err.response.data.message)
        })
    
       
}



  return (
    <>

     <ErrorModal error={error} onClear={errorReset} />
    <form onSubmit={updateUser}>
    <Flexed padding="10px 0px">
    <Flex>Full Name<br /><Input defaultValue={seluser?.fullname} onChange={e => setSelectedUser({...selectedUser,fullname:e.target.value})}/></Flex>
    <Flex>User Name<br /><Input  textTransform="none" defaultValue={seluser?.username} onChange={e => setSelectedUser({...selectedUser,username:e.target.value})}/></Flex>
    </Flexed>
    <Flexed padding="10px 0px">
    <Flex>Title<br /><Input defaultValue={seluser?.title} onChange={e => setSelectedUser({...selectedUser,title:e.target.value})} /></Flex>
    <Flex>Email<br /><Input textTransform="none" type="email" defaultValue={seluser?.email} onChange={e => setSelectedUser({...selectedUser,email:e.target.value})} required /></Flex>
    </Flexed>
    <Flexed padding="10px 0px">
    <Flex>Change Password<br /><Input type="password" textTransform="none" disabled={changepass} onChange={checkconfirm} bgColor={changepass ? "lightgrey" : ""} /></Flex>
    <Flex>Confirm Password<br /><Input  type="password" textTransform="none" disabled={changepass} bgColor={changepass ? "lightgrey" : ""} onChange={confirmpass} required/><br /></Flex>

    <div textAlign="right" padding="0px 30px 0px 0px">{passworderror ? <span style={{position:"absolute",top:"-5px",right:"19%",color:"red",fontWeight:"bold"}}>Passwords do not match</span> : "" }</div>
    <div style={{ textAlign:"right",width:"100%",position:"absolute",bottom:"-12px",right:"10px",zIndex:"100"}}><Checkbox type="checkbox" width="3%" onChange={() => setChangepass(!changepass)} checked={!changepass}/><span> Change Password</span></div>
    </Flexed> 
     <Flexed padding="15px 0px">
     <Flex>Status<br /> 
     <SelectBox width="90%"  value={selectedUser.status} onChange={e => setSelectedUser({...selectedUser,status:e.target.value})}>
          
            <option value="0">Not Active</option>
            <option value="1" >Active</option>
      
        </SelectBox>
        </Flex>
    <Flex>Type<br /> 
    <SelectBox width="90%"  value={selectedUser.type} onChange={e => setSelectedUser({...selectedUser,type:e.target.value})}>
        <option value="0" >User</option>
       {user.type ===1 && <option value="1" >System User</option>}
      </SelectBox></Flex>
     <Flex>Auth<br />
      <SelectBox width="90%"  value={selectedUser.auth} onChange={e => setSelectedUser({...selectedUser,auth:e.target.value})} required>
        <option value="0">User</option>
        {user.auth === 6 && selectedUser.type === 1 && <option value="6">Admin</option>}
        {user.auth === 6 || user.auth === 1 ? <option value="1">Account Manager</option> : ""}
      </SelectBox>
      </Flex>

    
    
    
    </Flexed>

    <Flexed justify="space-between" padding="0px 10%">{selectedUser.fullname === "Admin" ? <p>Admin account in demo can not be updated</p> : <Button type="submit" disabled={passworderror}>Update</Button>}<Button type="button" onClick={closeModal}>Cancel</Button></Flexed>
    <Flexed justify="flex-end" padding="0px 10%">{user.auth === 0 || user.username === "Admin" ? '' : <Button background="#ff6961" hbackground="red" type="button" onClick={DeleteUser}>Delete User</Button>}</Flexed>

    </form>
   
    </>
  )
}

export default EditUser