import React , { useRef,useEffect, useState } from 'react'
import PLS from '../../assets/plsprint.png'
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom'
import axios from 'axios'

const Ship = () => {
    const params = useParams();
    const componentRef = useRef();
    const datenow = new Date();

    const [loopnumber, setLoopnumber] = useState(null)
    const [cartons, setCartons] = useState(0)
    const [total, setTotal] = useState(0)
    const [poinfo, setPoinfo] = useState([])
    const [ordered, setOrdered] = useState(0)
    const id = params.id
    const [shipped, setShipped] = useState("")



useEffect(() => {
const getpoinfo = async() => {
   // await axios.get('https://plsprintdemo.herokuapp.com/api/orders/ship',id)
    await axios.get(`https://plsprintdemo.herokuapp.com/api/orders/ship/${id}`)
    .then(res => setPoinfo(res.data.order))
    .catch(err => console.log(err))
}

getpoinfo()
 

},[])

useEffect(() => {
   setLoopnumber(loopnumber => 15 - poinfo.items?.length)


},[poinfo])


useEffect(() => {
    const setTotalQuantity = () => {
        console.log('ran')
         for (let i = 0; i <= poinfo.items?.length -1 ; i++) {
           
           setOrdered(ordered => ordered + parseInt(poinfo.items[i].quantity))
         }
     }
     setTotalQuantity()
},[loopnumber])
//console.log(loopnumber)
console.log(shipped)


const loop = () => {
    let ret = [];

    for (let i = 1; i <= loopnumber; i++) {
        ret.push(<div style={{display:"flex",borderBottom:"1px solid black"}}>
        <div style={{flex:"4",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}>&nbsp;</div>
        <div style={{flex:"1",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}></div>
        <div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>&nbsp;</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>&nbsp;</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>&nbsp;</div>
<div style={{flex:"1",textAlign:"center",padding:"7px 5px"}}>&nbsp;</div>
        </div>)
    }
    return ret
}












  return (
    <>
   <div style={{position:"absolute",top:"250px",right:"23vw",zIndex:"100"}}>  <ReactToPrint
        trigger={() => <button style={{padding:"8px 25px"}}>Print Packing List</button>}
        content={() => componentRef.current}
      /></div>
    <div ref={componentRef} style={{position:"relative",padding:"40px",maxWidth:"1200px",margin:"0px auto",fontSize:"1.3rem"}}>
    <div style={{display:"flex",}}>
        <div style={{flex:"1.2"}}><img src={PLS} alt="" style={{height:"80%"}}/></div>
        <div style={{flex:"1",fontSize:"1.3rem"}}>
4250 118th Ave N<br />Clearwater,Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com
        </div>
        <div style={{flex:"1.9",fontSize:"2rem", fontWeight:"bold",textAlign:"center",marginTop:"-8px",padding:"0"}}>Packing List</div>
    </div>
  
    <div style={{display:"flex",marginTop:"40px"}}>
        <div style={{flex:"1.3",fontSize:"1.3rem", fontWeight:"bold"}}>Sold To<br /><span style={{fontSize:"1.3rem", fontWeight:"normal"}}>PSCU Financial Services<br />560 Carillion Parkway<br />St. Petersburg, Fl 33716<br />Phone: (727)318-6721</span></div>
        <div style={{flex:"1",fontSize:"1.3rem", fontWeight:"bold"}}>Ship To <br /><span style={{fontSize:"1.3rem", fontWeight:"normal"}}>PSCU Financial Services<br />560 Carillion Parkway<br />St. Petersburg, Fl 33716<br />Phone: (727)318-6721</span></div>
        <div style={{flex:".2",fontSize:"1.3rem", fontWeight:"bold"}}></div>
      
        <div style={{flex:"1",fontSize:"1.3rem", fontWeight:"bold"}}>Order #: <span style={{fontSize:"1.3rem", fontWeight:"normal"}}>{poinfo.orderid}</span><br /> Date: <span style={{fontSize:"1.3rem", fontWeight:"normal"}}>{datenow.toLocaleDateString()}</span></div>
    </div>
 
<div style={{width:"100%",border:"1px solid black",marginTop:"20px"}}>

<div style={{display:"flex",backgroundColor:"rgb(0,0,0,.2)",color:"black",borderBottom:"1px solid black",boxSizing:"border-box",fontWeight:"bold"}}>
<div style={{flex:"4",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}>Item</div>
<div style={{flex:"1",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}>Item Id #</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Ordered</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Cartons</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Per Carton</div>
<div style={{flex:"1",textAlign:"center",padding:"7px 5px"}}>Shipped</div>
</div>
{
poinfo.items?.map((item,index) => {

 return   <div style={{display:"flex",borderBottom:"1px solid black"}}>
        <div style={{flex:"4",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}>{item.name}</div>
        <div style={{flex:"1",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}>{item.stockid}</div>
        <div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{item.quantity}</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}><input className="box" name="cartons" type="text" 
    style={{width:"100%",fontSize:"1.3rem",textAlign:"center"}}/></div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}><input className="box" type="text"  name="percarton"  style={{width:"100%",fontSize:"1.3rem",textAlign:"center"}}/></div>
<div style={{flex:"1",textAlign:"center",padding:"7px 5px"}}><input className="box" type="text" style={{width:"100%",fontSize:"1.3rem",textAlign:"center"}} defaultValue={item.quantity}/></div>
        </div>
        
})


}
{loop()}
<div style={{display:"flex",backgroundColor:"rgb(0,0,0,.2)",color:"black",borderBottom:"1px solid black"}}>
<div style={{flex:"4",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:"1",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px",textAlign:"right"}}><b>Totals:</b></div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{ordered}</div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:"1",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:"1",textAlign:"center",padding:"7px 5px"}}><input className="box2" type="text"  value={ordered} name="totals" onChange={e => setShipped(e.target.value)} style={{width:"100%",fontSize:"1.3rem",textAlign:"center"}}/></div>
</div>

</div>
<div style={{position:"relative",height:"300px",boxSizing:"border-box"}}>

<div style={{display:"flex",width:"100%",height:"100%",gap:"50px",alignItems:"flex-end"}}>
<div style={{flex:"1",fontSize:"1.8rem"}}>Thank You<br />We Appreciate<br />Your Business</div>

<div style={{flex:"2",borderBottom:"1px solid black",fontSize:"1.8rem"}}><b>Received By:</b><br />Sign:<br /><div style={{height:"100px",width:"100%"}}></div></div>
<div style={{flex:"2",borderBottom:"1px solid black",fontSize:"1.8rem"}}><br />Print:<br /><div style={{height:"100px",width:"100%"}}></div></div>
</div>
</div>





  </div>
  </>
  )
}

export default Ship