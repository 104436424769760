import {useState,useEffect,useContext,useRef} from 'react'
import {Flexed,Flex,Img,Input,Button } from '../../shared/Styles'
import axios from 'axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from 'react-router-dom'
import UserContext from '../../context/UserContext'

const OrderHistory = () => {
    const [history, setHistory] = useState([]);
    const [replenishlist, setReplenishlist] = useState([]);
   const [twist, setTwist] = useState(false)
    const [switchit, setSwitchit] = useState(false);
    const { user } = useContext(UserContext)
console.log(user)
    useEffect(() => {
    const getHistory = async() => {
        axios
        .get(`https://plsprintdemo.herokuapp.com/api/orders`)
        .then(function (response) {
         setHistory(response.data.allorders);
           
        });
    }


    const getReplensih = async() => {
      axios
      .get(`https://plsprintdemo.herokuapp.com/api/orders/replenish`)
      .then(function (response) {
        setReplenishlist(response.data.allproducts);
    
      });
  }
    getHistory();
    getReplensih();
},[twist])


const addreplenish = async(id,i,repid) => {
const re = {
  id,
  addstock:myRefs.current[i].value,
  repid
}
const go = async() => {
  await axios
.post(`https://plsprintdemo.herokuapp.com/api/orders/addtostock`,re)
.then(function (response) {
  setTwist(!twist)

})
}
console.log(re)
go()

}

const myRefs= useRef([]);

const changestatus = async(e,orderid) => {

  if(e.target.value === "Shipped"){
    const info = {
      id:orderid,
      status:e.target.value
    }
    await axios.patch('https://plsprintdemo.herokuapp.com/api/orders/patch/status',info)
   // await axios.patch('https://plsprintdemo.herokuapp.com/api/orders/patch/status',info)
    .then(res => navigate(`/ship/${orderid}`))
    .catch(err => console.log(err))
  }
  
}


const navigate = useNavigate();

  return (
    <>
    <div style={{width:"1200px",margin:"50px auto"}}>
<div style={{fontSize:"1.5rem",padding:"15px 0px"}}>Item History {!switchit && history?.length > 0 ? ` - ${history?.length} Transactions` : ` - ${replenishlist?.length} Transactions`}</div>
{ history?.length === 0 ? <p>No History of this order is available</p> : ''}

<Tabs>
    <TabList>
      <Tab style={{color:"var(--primaryBlue)",fontSize:"1.2", fontWeight:"bold"}} onClick={() => setSwitchit(false)}>Orders</Tab>
      <Tab style={{color:"var(--primaryBlue)",fontSize:"1.2", fontWeight:"bold"}} onClick={() => setSwitchit(true)}>Replenishment Orders</Tab>
    </TabList>

    <TabPanel>
     <>
     {history?.length === 0 && <p>No Order History Available</p>}
     {history?.map((item) => {
  
     return(
    <>
  
 <Flexed margin="40px 0px 0px 0px " key={item?._id} style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">
 <Flex margin="0">Order # {item?.orderid}</Flex>
 
 <Flex>Ordered By: {item.who?.fullname}</Flex>
 <Flex>Date Ordered: {new Date(item?.createdAt).toDateString()}</Flex>
 <Flex>Status: 
  {user.type === 1 ? <select defaultValue={item.status} onChange={e => changestatus(e,item._id)}><option value="Processing">Processing</option><option value="Shipped">Shipped</option></select> : item?.status}</Flex>
  </Flexed>
 {history.length > 0 &&  <Flexed  style={{background:"var(--primamryBlue)",opacity:".7",color:"white"}} margin="-1px 0px 0px 0px" padding="2px 5px"  border="1px solid lightgrey">
  <Flex></Flex>
 <Flex>Item #</Flex>
<Flex flex="2.1">Item Name</Flex>
<Flex>Quantity</Flex>


</Flexed>}
  {item.items.map((prod) => {
    return (
    <Flexed key={prod?._id}  onClick={() => navigate(`/inventory/item/${prod.id}`)} margin="-2px 0px 0px 0px" padding="10px 5px" className="child" border="1px solid lightgrey">
      <Flex><Img src={prod?.img} alt="" maxHeight="30px"  border="1px solid rgb(0,0,0,.3)" className="hover zoom"/></Flex>

 <Flex>{prod?.stockid}</Flex>
<Flex flex="2.1">{prod?.name}</Flex>
<Flex>{prod?.quantity}</Flex>

</Flexed>
    )
    
  })}
 
        
        </>
     )
  
   }) 
  
}
     </>
    </TabPanel>
    <TabPanel>
    {replenishlist?.length === 0 && <p>No Replenishment History Available</p>}
    {replenishlist?.length > 0 &&  <Flexed  style={{background:"var(--primamryBlue)",opacity:".7",color:"white"}} margin="-1px 0px 0px 0px" padding="2px 5px"  border="1px solid lightgrey">
  <Flex></Flex> 
 <Flex>Item #</Flex>
<Flex >Item Name</Flex>
<Flex>Order By</Flex>
<Flex>Order Date</Flex>
<Flex>Requested</Flex>
<Flex>Replenished</Flex>
<Flex></Flex>
</Flexed>}
{replenishlist?.map((prod,i) => {
    return (
    <Flexed key={prod?._id} margin="-2px 0px 0px 0px" padding="10px 5px" className="child" border="1px solid lightgrey">
<Flex><Img src={prod.stockid?.img} alt="" maxHeight="30px"  border="1px solid rgb(0,0,0,.3)" className="hover zoom"/></Flex>
 <Flex>{prod.stockid?.stockid}</Flex>
<Flex >{prod.stockid?.name}</Flex>
<Flex>{prod.who?.fullname}</Flex>
<Flex >{new Date(prod?.createdAt).toDateString()}</Flex>
<Flex>{prod?.quantity}</Flex>
<Flex>{user.type === 1 ? <Input ref={el => (myRefs.current[i] = el)} type='text' defaultValue={prod?.replenished} disabled={prod?.replenished > 0 && 'true'}/> : prod.replenished} </Flex>
<Flex>{user.type === 1 && prod?.replenished === 0 ?<Button onClick={() => addreplenish(prod.stockid.id,[i],prod._id)}>Add To Instock</Button> : ''}</Flex>

</Flexed>
    )
    
  })}
    </TabPanel>
  </Tabs>







    </div>
    </> 
  )
}

export default OrderHistory