import {Flexed,Flex,Img,Input,Button,Label,SelectBox } from '../../shared/Styles'
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import UserContext from '../../context/UserContext';


const Addnew = ({id,setModal,twist, setTwist}) => {
    const {user} = useContext(UserContext);
const [product, setProduct] = useState({
    name:'',
    reorderlvl:'',
    instock:'',
    stockid:'',
    price:'',
    taxable:'',
    cat:'',
    size:'',
    type:'',
    colors:'',
    perpack:'',
    mustorder:'',
    img:''
});


  console.log(product)


    const update = (e) => {
        const { name ,value } = e.target
        setProduct({...product,[name]:value})
        }


const addnew = async() => {
    
//const newid =  await axios.get(`https://plsprintdemo.herokuapp.com/api/orders/nextid`)
 // await axios.patch(`https://plsprintdemo.herokuapp.com/api/products/patch`,product)
 await axios.post(`https://plsprintdemo.herokuapp.com/api/products`,product)
  .then(async(response) => {

    reset()
    
    })
    //
       
}
 const reset = () => {
    setTwist(!twist);

    setModal(false)
 }


const handlesubmit = (e)=> {
e.preventDefault();
addnew()
}


 
  return (
    <form onSubmit={handlesubmit} >
    <div style={{padding:"20px",width:"100%"}}><span style={{fontSize:"1.3rem",fontWeight:"bold",color:"var(--primamryBlue)"}}>Item # {product?.stockid}</span><br /><br />
<Flexed padding="0px 0px 15px 0px">
<Flex><Label>Product Name</Label><br /><Input type="text" defaultValue={product?.name} onChange={update} name="name" required /></Flex>
<Flex><Label>Item Number</Label><br /><Input type="text" defaultValue={product?.stockid} onChange={update} name="stockid"required  /></Flex>
<Flex><Label>Category</Label><br />
<SelectBox defaultValue={product?.cat}  onChange={update} name="cat" width="92%">
    <option value="Envelopes">Envelopes</option>
    <option value="Letter Heads">Letter Heads</option>
    <option value="Business Cards">Business Cards</option>
    </SelectBox>
    </Flex>
</Flexed>
<Flexed  padding="0px 0px 15px 0px">
<Flex><Label>Reorder At</Label><br /><Input type="text" defaultValue={product?.reorderlvl} onChange={update} name="reorderlvl" required /></Flex>
<Flex><Label>In Stock</Label><br /><Input type="text" defaultValue={product?.instock}  onChange={update} name="instock" required /></Flex>
<Flex><Label>Paper Type</Label><br /><Input type="text" defaultValue={product?.type} onChange={update} name="type" required /></Flex>
<Flex><Label>Size</Label><br /><Input type="text" defaultValue={product?.size} onChange={update} name="size" required  /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
<Flex><Label>Colors</Label><br /><Input type="text" defaultValue={product?.colors} onChange={update} name="colors" required /></Flex>
<Flex><Label>Per Package</Label><br /><Input type="text" defaultValue={product?.perpack} onChange={update} name="perpack" required /></Flex>
<Flex><Label>Must Order In</Label><br /><Input type="text" defaultValue={product?.mustorder} onChange={update} name="mustorder" required /></Flex>
<Flex><Label>Price</Label><br /><Input type="text" defaultValue={product?.price} onChange={update} name="price"/></Flex>
<Flex><Label>Taxable</Label><br /><Input type="text" defaultValue={product?.tax} onChange={update} name="tax"/></Flex>
</Flexed>
<Flexed justify="space-between" alignItems="flex-start">
   
    <Flex textAlign="right" padding="0px 20px 0px 0px"><Button type="submit" >Add New Product</Button><br /><br /><Button onClick={() => setModal(false)}>Cancel</Button></Flex>
</Flexed>

    </div>
    </form>
  )
}

export default Addnew