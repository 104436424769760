import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Homebg  from '../../assets/Asset2.png'
import { Flexed, Flex, Img, Button } from '../../shared/Styles'
import axios from 'axios'
const Home = () => {
    const [productlist, setProductlist] = useState([]);

useEffect(() => {
    axios
    .get("https://plsprintdemo.herokuapp.com/api/products")
    .then(function (response) {
        setProductlist(response.data.allproducts);
    });

},[]);


const navigate = useNavigate();
  return (
    <>
    <div style={{height:"300px",background:"#10B8E0"}}><Img src={Homebg} alt="" width="100%" maxHeight="250px" margin="0px 0px 0px -2px"/></div>
    <div style={{padding:"20px 0px",fontSize:"1.5rem",fontWeight:"bold",color:"var(--primamryBlue)",textAlign:"center"}}>Current Items For Ordering</div>
    <Flexed wrap="wrap"  margin="20px auto 0px auto" width="1250px" justify="flex-start">


{
    productlist?.map(item => (
<Flex key={item.stockid} flex=" " fontSize="1.1rem" width="350px" margin="0px 15px 20px 15px" textAlign="center" ><Img className="hover" src={`/${item.img}`} alt=""  width="90%" boxShadow="0 0 8px black" onClick={() => navigate(`/inventory/item/${item._id}`)}/>
<br />{item.stockid}<br />{item.name}<br /><Button onClick={() => navigate(`/inventory/item/${item._id}`)}>ORDER</Button></Flex>
    ))
}





    </Flexed>
    </>
  )
}

export default Home