
import './App.css'
import Dashboard from './pages/dashboard/Dashboard';
import { UserProvider } from './context/UserContext'
import { CartProvider } from './context/CartContext'

function App() {
  return (
    <div className="App">
      <UserProvider>
<CartProvider>  
   <Dashboard />
</CartProvider>
</UserProvider>
    </div>
  );
}

export default App;
