import {Flexed,Flex,Img,Input,Button,SelectBox,Checkbox } from '../../shared/Styles';
import { MdEditNote } from 'react-icons/md';
import Modal from '../../components/modal/Modal';
import { useState,useEffect,useContext} from 'react'
import UserContext from '../../context/UserContext';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import SystemUser from './SystemUser';
import NormUser from '../users/NormUser'
import EditUser from './EditUser';
import { Capitalize } from '../../shared/Formatters'
import { useCookies } from "react-cookie";


const Users = () => {
const [showmodal, setShowmodal] = useState(false);
const [adduser, setAdduser] = useState(false);
const [selectedUser, setSelectedUser] = useState({});
const [addnormuser, setAddnormuser] = useState(false);
const [userList, setUserList] = useState([]);
const [usernorm, setUsernorm] = useState([]);
const [changepass, setChangepass] = useState(true);
const [passworderror, setPassworderror] = useState(false);
const [confirm, setConfirm] = useState('');
const [pass, setPass] = useState('');
const [twist, setTwist] = useState(false);
const [cookies, setCookie, removeCookie] = useCookies();

const { user,checkCookie,getUserinfo } = useContext(UserContext);

const Nav = useNavigate();


useEffect(() => {
    checkCookie();
},[])

useEffect(() => {
    
    const getAll = async() => {
        axios
    .get(`https://plsprintdemo.herokuapp.com/api/users/system`)
    .then(function (response) {
        setUserList(response.data.allsystemusers);
        
    }); 
    }
    const getNormal = async() => {
        axios
    .get(`https://plsprintdemo.herokuapp.com/api/users/all`)
    .then(function (response) {
        setUsernorm(response.data.allusers);
        
    }); 
    }
    getAll();
    getNormal();
   

},[twist])



const authText = (item) => {
switch(item){
case 0: return "User";
break;
case 1: return "Account Manager";
break;
case 6: return "Admin";
break;
}
}




  return (
    <div style={{}}>

<Modal show={showmodal} header={`Edit user - ${selectedUser.fullname}`}><EditUser setShowmodal={setShowmodal} twist={twist} setTwist={setTwist} seluser={selectedUser} /></Modal>

<Modal show={adduser}  header={`Add System User`}><SystemUser setAdduser={setAdduser} twist={twist} setTwist={setTwist} /></Modal>

<Modal show={addnormuser}  header={`Add New User`}><NormUser setAdduser={setAddnormuser} twist={twist} setTwist={setTwist} /></Modal>








    <div style={{width:"1200px",margin:"50px auto"}}>

        {user?.type === 1 && 
<>
<Flexed style={{fontSize:"1.5rem",padding:"15px 0px"}} justify="space-between">System Users <Button onClick={() => setAdduser(true)}>Add System User</Button></Flexed>

    <Flexed  style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">

<Flex>Name</Flex>
<Flex flex="1.5">Title</Flex>
<Flex flex=".6">Status</Flex>
<Flex>Auth</Flex>
<Flex flex=".3"></Flex>
    </Flexed>

    {
     
userList?.map(item => {
  return <Flexed key={item._id}padding="15px 10px" border="1px solid lightgrey" className="child">
    <Flex>{Capitalize(item.fullname)}</Flex>
    <Flex flex="1.5">{item.title}</Flex>
    <Flex flex=".6">{item.status === 1 ? "Active" : "Not Active"}</Flex>
    <Flex>{authText(item.auth)}</Flex>
    <Flex flex=".3">{user.auth === 6 || user.auth === 1 || user.userid === item._id ?<MdEditNote style={{fontSize:"1.5rem"}} onClick={ () => {setShowmodal(true); setSelectedUser(item)} }></MdEditNote> : ''}</Flex>
        </Flexed>
})

}
</>  
}
  
{user?.type === 0  || user?.type === 1 ?
<>
<Flexed style={{fontSize:"1.5rem",padding:"15px 0px"}} justify="space-between">Users{` - ${usernorm.length}`} {user?.auth === 1 || user?.auth === 6 ? <Button onClick={() => setAddnormuser(true)}>Add New User</Button> : ''}</Flexed>

    <Flexed  style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">

<Flex>Name</Flex>
<Flex>Title</Flex>
<Flex flex="1.5">Email</Flex>
<Flex flex=".6">Status</Flex>
<Flex>Auth</Flex>
<Flex flex=".3"></Flex>
    </Flexed>

    {
     
     usernorm?.map(item => {
  return <Flexed key={item._id}padding="15px 10px" border="1px solid lightgrey" className="child">
    <Flex>{Capitalize(item.fullname)}</Flex>
    <Flex>{item.title}</Flex>
    <Flex flex="1.5" textTransform="none">{item.email}</Flex>
    <Flex flex=".6">{item.status === 1 ? "Active" : "Not Active"}</Flex>
    <Flex>{authText(item.auth)}</Flex>
    <Flex flex=".3">{user?.auth === 6 || user?.auth === 1 || user?.userid === item._id ? <MdEditNote style={{fontSize:"1.5rem"}} onClick={ () => {setShowmodal(true); setSelectedUser(item)} }></MdEditNote>: ''}</Flex>
        </Flexed>
})

}
</>  :''
}




    </div></div>
  )
}

export default Users