import React, { createContext, useState } from "react";
import axios from 'axios'
import { sendEmail } from '../email/Email'
import { sendconfirmation, sendconfirmation2} from "../email/sendMail";


const CartContext = createContext();

export function CartProvider({ children }) {



  const [user, setUser] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');



  const delCart = (stockid,quantity,id) => {
 const newPeople = items.filter((item,index) => {
      return index !== id;
    });
    setItems(newPeople);
  };

  const updateQuantity = (id, newquantity) => {
    const exist = items.find((x) => x.id === id);
    setItems(
      items.map((x) => (x.id === id ? { ...exist, quantity: newquantity } : x))
    );
  };

  const loop = async() => {
    let q=0;
    for (let i = 1;i<=items.length;i++){
     await axios.patch("https://plsprintdemo.herokuapp.com/api/products/count", {
       id: items[q].id,
       newquantity: items[q].quantity
    })  
    q++
  }
}


  const addOrder = async(newid,who,user) => {

       await axios.post("https://plsprintdemo.herokuapp.com/api/orders", {
       who,
       orderid:newid,
       items
    })

    .then(response => {
      console.log(response)
      
    }).then( () => {
      loop()
    })
   
    let today = new Date();
      const firstName = user.fullname?.split(" ").shift();
      const mailItems = {
        fullname:user.fullname,
        to:user.email,
        username:firstName,
        ordercount:items.length,
        ordernumber:newid,
        orderdate:today.toLocaleDateString(),
        table:`<table border="1" >
        <thead><tr><th width="10%">Item ID #</th><th width="60%">Item Name</th><th>Quantity Ordered</th></tr></thead>
        ${items.map(element => {return `<tr><td width="10%">${element.stockid}</td><td width="60%">${element.name}</td><td >${element.quantity}</td></tr>`}).join(" ")}</table>`,
        tabletwo:` <table border="1" width="100%;margin:0px 0px0px 20px;" >
        <thead><tr style="padding:15px 0px" ><th style="width:10%;text-align:left;padding:15px 0px">Item ID #</th><th  style="width:30%;text-align:left;padding:15px 0px">Item Name</th><th  style="width:30%;text-align:left;padding:15px 0px">Quantity Ordered</th><th  style="width:30%;text-align:left;padding:15px 0px">Pulled</th></tr></thead><tbody>
        ${items.map(element => {return `<tr><td style="width:10%;text-align:left;padding:15px 0px">${element.stockid}</td><td style="width:30%;text-align:left;padding:15px 0px">${element.name}</td><td style="width:30%;text-align:left;padding:15px 0px">${element.quantity}</td><td style="width:30%;text-align:left;padding:15px 0px"></td></tr>`}).join(" ")}</tbody></table>`
            }
          //  sendEmail(mailItems)
          sendconfirmation(mailItems)
          sendconfirmation2(mailItems)
  }
   






  const errorReset = () => {
    setError(null);
}


  return (
    <CartContext.Provider
      value={{
        setUser,
        user,
        items,
        setItems,
        delCart,
        addOrder,
        errorReset,
        error,
        setError,
      
       
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;
