
import Modal from './Modal';
import {Button} from '../../shared/Styles'

const SuccessModal = props => {
  return (
    <Modal
      onCancel={props.onClear}
      header="Your Order Has Been Received. Thank You"
      show={!!props.error}
      footer={<Button onClick={props.onClear}>{props.text}</Button>}
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default SuccessModal;
