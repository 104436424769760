import axios from "axios";
import { toast } from 'react-toastify'






export const sendNotifications = (reorderinfo) => {

    const r = {
        "quantity":reorderinfo.quantity,
        "itemnum":reorderinfo.itemnum,
        "name":reorderinfo.name,
        "who":reorderinfo.who,
        "company":reorderinfo.company,
   
    }
  
    const sendmail = async() => {
    await axios.post(`https://plsemail-1a47a63616b9.herokuapp.com/pscu/replenish`,r)
   // await axios.post(`http://localhost:5005/pscu/replenish`,r)
    .then(res => {console.log(res.data);toast.success('Email Sent')})
    .catch(err => console.log(err))
    }
 sendmail()

}


export const sendconfirmation = (mailItems) => {


  
    const sendmail = async() => {
    await axios.post(`https://plsemail-1a47a63616b9.herokuapp.com/pscu/confirm`,mailItems)
    //await axios.post(`http://localhost:5005/pscu/confirm`,mailItems)
    .then(res => {console.log(res.data);toast.success('Email Sent')})
    .catch(err => console.log(err))
    }
 sendmail()

}

export const sendconfirmation2 = (mailItems) => {


  
    const sendmail = async() => {
    await axios.post(`https://plsemail-1a47a63616b9.herokuapp.com/pscu/confirm2`,mailItems)
    //await axios.post(`http://localhost:5005/pscu/confirm2`,mailItems)
    .then(res => {console.log(res.data);toast.success('Email Sent')})
    .catch(err => console.log(err))
    }
 sendmail()

}


