import {useContext,useState} from 'react'
import CartContext from '../../context/CartContext'
import UserContext from '../../context/UserContext'
import {Flexed,Flex,Img,Input,Button } from '../../shared/Styles'
import { MdRemoveShoppingCart } from 'react-icons/md'
import Nav from '../../components/nav/Nav'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import SuccessModal from '../../components/modal/SuccessModal';
import Confetti from '../../components/modal/Confetti'


const Cart = () => {

const {items,delCart,setItems,addOrder,error,errorReset,setError} = useContext(CartContext);
const {user} = useContext(UserContext);
const [showComplete, setShowcomplete] = useState(false);
const [newordernumber, setNewordernumber] = useState();
const NAV = useNavigate();


const getNextOrderNumber = async() => {
    let newid;
    await axios
    .get(`https://plsprintdemo.herokuapp.com/api/orders/nextid`)
    //.get(`https://pscuapi.herokuapp.com/api/orders`)
    .then(function (response){
    
        setNewordernumber(response.data[0].orderid)
    })
   
 


}


console.log(items)

const addOrders = async() => {

let newid;
    await axios
    .get(`https://plsprintdemo.herokuapp.com/api/orders/nextid`)
  
    .then(function (response){

     if(response.data.length === 0){
        newid = 45982;
     }else{
        newid = response.data[0].orderid +1
     }
   
        addOrder(newid,user.userid,user)
    }).finally(() => {
        try{
            setError(true)
            setItems([])
           setShowcomplete(true)
          setTimeout(() => {
           setShowcomplete(false)

         },4000);
         
            }catch(err){
        console.log(err)
            }
    })
    .catch(err => {console.log(err)})


}


const table = () => {
return (
    <>
<div>

</div>
</>
)
}



const mailit = () => {
let today = new Date();
 

/*const neworder = {
    name:,
    instock,
    stockid,
    price,
    taxable,
    cat,
    size,
    type,
    colors,
    perpack,
    mustorder,
    who,
    quantity,
    when,
    id,
    orderid,
    dbid,
    quantityleft,
    status, 
}
*/
   

}

const homeset = () => {
    setError(false)
    NAV("/");
}




  return (
    <>
    {error && <Confetti />}
    <SuccessModal text="Return to Home Page" error={error} onClear={homeset} />
    <div style={{width:"1200px",margin:"50px auto"}}>
    <div style={{fontSize:"1.5rem",padding:"15px 0px"}}>Cart Items {items.length > 0 && ` - ${items.length < 2 ? `${items.length} Item` : `${items.length} Items`}`}</div>
    {
        items.length === 0 ? <p>No currently no items inside your cart.</p> :
    
        <Flexed  style={{background:"var(--primamryBlue)"}}  color="white" padding="5px">
    <Flex></Flex>
    <Flex>Item#</Flex>
    <Flex>Name</Flex>
    <Flex> Category</Flex>
    <Flex>Ordered</Flex>
    <Flex></Flex>
    <Flex flex=".3"></Flex>
    
    
        </Flexed>
    }

    {items?.map((item,index) => {

     
      return    <Flexed  key={index} style={{padding:"5px",border:"1px solid lightgrey"}} className="child" >
         <Flex textAlign="center"><Img border="1px solid lightgrey" src={item?.img} maxHeight="50px" className="hover zoom"/></Flex>
         <Flex >{item?.stockid}</Flex>
         <Flex>{item?.name}</Flex>
         <Flex >{item?.cat}</Flex>
         <Flex >{item?.quantity}</Flex>
         <Flex ></Flex>
         <Flex flex=".3"><MdRemoveShoppingCart onClick={() => delCart(item?.stockid,item?.quantity,index)}/></Flex>
             </Flexed>
    })}
      <div className={`alert-success ${showComplete ? 'alert-shown' : 'alert-hidden'}`}>
          <strong>Your Order has been submitted. Thank you</strong> 
        </div>
    <div style={{float:"right"}}>
   {items.length > 0 && <Button onClick={addOrders}>Order Items</Button>}
   {/*items.length > 0 && <Button onClick={ mailit}>Mail Test</Button>*/}
   
    </div>
   
        </div>
        {table()}
        </>
  )
}

export default Cart