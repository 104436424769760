import styled from 'styled-components'



export const Wrap = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
background-color:${props => props.bgcolor || "var(--primamryBlue)"};
color:${props => props.color || "white"};


`;