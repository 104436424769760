import React, { createContext, useState, useEffect } from "react";
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'

const TempContext = createContext();

export function TempProvider({ children }) {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [twister, setTwister] = useState(false)
  const [temp, setTemp] = useState([]);
  const [inventory, setInventory] = useState({
    "one ": 1000,
    "two":20000
  });



  return (
    <TempContext.Provider
      value={{
 temp,
 setTemp,
 inventory,
 setInventory
         
      }}
    >
      {children}
    </TempContext.Provider>
  );
}

export default TempContext;
