export const PhoneNumber = (value) => {
    value = value.replace(/[^\d]/g, '');
    if(!value){
return value;
    }
return `(${value.slice(0,3)}) ${value.slice(3,6)} - ${value.slice(6,10)}`;
}

export const Zipcode = (value) => {
    if(!value || value.length < 6){
return value;
    }
return `${value.slice(0,5)} - ${value.slice(6,11)}`;
}

export const Capitalize = (item) => {
 
    item.toLowerCase();
   let words = item.split(" ");

 

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
   
    return words.join(" ");
    
}

export const Dollar = (item) => {
    return item.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
}